import { Controller } from "@hotwired/stimulus";
import { put, get, post, patch, destroy } from "@rails/request.js";

export default class extends Controller {
  connect() {
    console.log("Hello KPI!");
  }
  addThis(e) {
    e.preventDefault();
    let parent = e.target.closest("a");
    console.log("PARENT", parent);
    this.addNewKpi(parent.dataset.team, parent.dataset.kpi);
    let current_list = document.getElementById("current-list");
    this.addLineToList(
      current_list,
      parent.dataset.text,
      parent.dataset.team,
      parent.dataset.kpi
    );
    this.removeLineFromList(e);
  }

  async addNewKpi(team_id, kpi_id) {
    const response = await post("/add_kpi", {
      body: JSON.stringify({
        team_id: team_id,
        kpi_id: kpi_id,
      }),
    });
    if (true) {
      console.log("RESPONSE", response);
    }
  }
  async removeKpi(team_id, kpi_id) {
    const response = await post("/remove_kpi", {
      body: JSON.stringify({
        team_id: team_id,
        kpi_id: kpi_id,
      }),
    });
    if (true) {
      console.log("RESPONSE", response);
    }
  }
  removeThis(e) {
    console.log("REMOOVING");
    e.preventDefault();
    let parent = e.target.closest("a");
    let li_parent = e.target.closest(".list-group-item");
    this.removeKpi(parent.dataset.team, parent.dataset.kpi);
    li_parent.style.display = "none";
    let available_list = document.getElementById("available-list");
    this.addLineToList(
      available_list,
      parent.dataset.text,
      parent.dataset.team,
      parent.dataset.kpi
    );
  }
  removeNonPersisted(e) {
    e.preventDefault();
    this.removeKpi(e.target.dataset.team, e.target.dataset.kpi);
    this.removeLineFromList(e);
    let available_list = document.getElementById("available-list");
    this.addLineToList(
      available_list,
      e.target.dataset.text,
      e.target.dataset.team,
      e.target.dataset.kpi
    );
  }

  async addLineToList(list, text, team_id, kpi_id) {
    //let available_list = document.getElementById("available-list");
    const response = await get(`/key_performance_indicators/${kpi_id}.json`);
    if (response.ok) {
      const body = await response.json;
      console.log("BODY RESULT", body);
      let row_text = text;
      let li = document.createElement("li");
      // Build Button
      let button =
        list.id === "available-list"
          ? this.createAddLink(kpi_id, text, team_id)
          : this.createRemoveLink(kpi_id, text, team_id);
      // Build header row
      let top_row = document.createElement("div");
      top_row.className = "row";
      let text_node = document.createTextNode(row_text);
      let header_div = document.createElement("h4");
      header_div.className = "col-md-11";
      header_div.appendChild(text_node);
      top_row.appendChild(header_div);
      if (list.id !== "available-list") {
        top_row.appendChild(button);
      }

      // Build body
      let description_div = document.createElement("p");
      let value_type_div = document.createElement("div");
      let descr = document.createTextNode(body.description);
      let value_type = document.createTextNode(`Type: ${body.value_type}`);
      value_type.className = "text-muted";
      value_type_div.appendChild(value_type);
      value_type_div.className = "text-muted";
      description_div.appendChild(descr);
      // Assemble
      li.appendChild(top_row);
      li.appendChild(description_div);
      li.appendChild(value_type_div);
      if (list.id === "available-list") {
        li.appendChild(button);
      }
      li.className = "list-group-item";
      list.appendChild(li);
    }
  }
  createAddLink(kpi_id, text, team_id) {
    let button = document.createElement("a");
    button.className = "add-icon no-underline";
    button.setAttribute("data-kpi", kpi_id);
    button.setAttribute("data-text", text);
    button.setAttribute("data-team", team_id);
    button.setAttribute("data-action", "click->addkpi#addThis");
    let icon = document.createElement("i");
    icon.classList.add("bi-plus-circle-fill");
    let span = document.createElement("span");
    span.classList.add("add-icon-text");
    let span_text = document.createTextNode(" Add to team");
    span.appendChild(span_text);
    icon.appendChild(span);
    button.appendChild(icon);

    return button;
  }
  createRemoveLink(kpi_id, text, team_id) {
    let button = document.createElement("a");
    button.className = "remove-link col-md-1 align-self-end no-underline";
    button.setAttribute("data-kpi", kpi_id);
    button.setAttribute("data-text", text);
    button.setAttribute("data-team", team_id);
    button.setAttribute("data-action", "click->addkpi#removeThis");
    let icon = document.createElement("i");
    icon.classList.add("bi-x-circle-fill");
    button.appendChild(icon);
    return button;
  }
  removeLineFromList(e) {
    let parent = e.target.closest(".list-group-item");
    parent.style.display = "none";
  }
}
