import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    let page = document.getElementById("wrapper");
    let primary = page.dataset.primaryColour;
    let contrast = page.dataset.contrastColour;
    console.log("dataset", primary);
    let root = document.documentElement;
    root.style.setProperty("--primary", primary);
    root.style.setProperty("--contrast", contrast);
    console.log("SET THEME!");
  }
}
